import React from 'react';
import { AdminPortal, useAuth } from '@frontegg/react';
import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  profileButtonContainer: {
    background: 'none',
    border: 0,
    paddingRight: '0 !important',
    display: 'inline-block',
  },
  profileButton: {
    border: '2px solid white',
    cursor: 'pointer',
  },

}));
const UserBadge = () => {
  const classes = useStyles();
  const { user } = useAuth();
  return (
    <button
      type="button"
      onClick={() => {
        AdminPortal.show();
      }}
      className={classes.profileButtonContainer}
    >
      <Avatar
        className={classes.profileButton}
        alt={user?.name || user?.email}
        src={user?.profilePictureUrl}
      />
    </button>
  );
};

export default UserBadge;
