import React from 'react';
import {
  styled,
  alpha,
} from '@mui/material/styles';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

export const useScrolledFromTop = ({ threshold = 20 } = {}) => {
  const [scrollTriggered, setScrollTriggered] = React.useState(false);
  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = Math.abs(currPos.y) > threshold;
    // console.log(currPos.y, threshold, isShow, scrollTriggered);
    if (isShow !== scrollTriggered) {
      setScrollTriggered(isShow);
    }
  }, [scrollTriggered]);
  return scrollTriggered;
};

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'drawerOpen',
})(({ theme, drawerOpen }) => {
  const trigger = useScrolledFromTop();

  return ({
    zIndex: theme.zIndex.drawer + 4,
    backgroundColor: alpha(theme.palette.background.default, 1),
    boxShadow: 'rgba(0,0,0,0) 0px 2px 10px',
    transition: theme.transitions.create(['width', 'margin', 'box-shadow'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(trigger && {
      backgroundColor: alpha(theme.palette.background.default, 0.7),
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 10px',
      backdropFilter: 'blur(6px)',
    }),
  });
});

const HidingAppBar = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide
      appear={false}
      direction="down"
      in={!trigger}
    >
      <StyledAppBar position="fixed" hidden={trigger}>
        <Toolbar>
          {children}
        </Toolbar>
      </StyledAppBar>
    </Slide>
  );
};

export default HidingAppBar;
