import categories from '@qloo/categories';
import { TABS } from './pages';
import ImageHighlightMissing from '../components/ImageHighlightMissing';
import Image from '../components/Image';
import usePermission, { usePermissionObject } from './usePermissionsBase';

const PAGE_KEYS = Object.keys(TABS);
const PAGE_PERM_KEYS = Object.keys(TABS).map((k) => `page.${k}`);
export const usePagePerms = () => {
  const pagePerms = usePermission(...PAGE_PERM_KEYS);
  const hasMultiplePages = pagePerms.filter((f) => f === true).length > 1;
  const firstUsablePage = TABS[PAGE_KEYS[pagePerms.indexOf(true)]];
  const validPages = pagePerms.map((f, i) => (f ? TABS[PAGE_KEYS[i]] : false)).filter((f) => f !== false);
  return { validPages, hasMultiplePages, firstUsablePage };
};

const CATEGORY_URNS = Object.values(categories).map(({ urn }) => urn);
const CATEGORY_PERM_KEYS = CATEGORY_URNS.map((urn) => `api.category.${urn}`);

export const useCategoryPerms = () => {
  const categoryPerms = usePermissionObject(...CATEGORY_PERM_KEYS);
  return Object.fromEntries(
    Object.entries(categoryPerms)
      .sort(([urn, enabled], [urnb, enabledb]) => ((enabled === enabledb) ? urn.localeCompare(urnb) : enabled ? -1 : 1))
      // remove 'api.category.' from 'api.category.urn:entity:actor'
      .map(([cat, enabled]) => [cat.substr(13), enabled]),
  );
};

// An array of urns from enabled categories
export const useCategoryPermUrns = () => {
  const catPerms = useCategoryPerms();
  return !!catPerms && Object.entries(catPerms).filter(([, enabled]) => enabled).map(([k]) => k);
};

export const useEntityImageComponent = () => {
  const [useQAImageComponent] = usePermission('feature.entity.qa-image-component');
  return useQAImageComponent ? ImageHighlightMissing : Image;
};
