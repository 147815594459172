import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Robot from './robot.png';

const styles = () => ({
  container: {
    marginLeft: '40px',
    marginTop: '60px',
  },
  h1: {
    fontSize: '22px',
  },
  h1sub: {
    fontWeight: 'normal',
    fontSize: '17px',
    marginLeft: '6px',
    color: 'gray',
  },
  h3: {
    fontWeight: 'normal',
    fontSize: '17px',
    fontFamily: "'Roboto',arial,sans-serif",
  },
  img: {
    float: 'left',
    marginRight: '40px',
  },
});

const AccessDenied = ({ classes, Button }) => (
  <div className={classes.container}>
    <img src={Robot} alt="Error" className={classes.img} />
    <h1 className={classes.h1}>
      403.
      <span className={classes.h1sub}>
        That&apos;s an error.
      </span>
    </h1>
    <h3 className={classes.h3}>
      This resource requires authentication.
    </h3>

    {
      Button && <Button />
    }

  </div>
);

export default withStyles(styles)(AccessDenied);
