import React, { useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import LogoSmLoading from './LogoSmLoading';

const useStyles = makeStyles(() => ({
  img: {
    flex: 1,
  },
  imageContainer: {
    display: 'flex',
    position: 'relative',
    // flexDirection: 'column',
  },
  imageLoading: {
    position: 'absolute',
  },
  defaultImage: {
    // width: '..',
    // height: '..',
  },
}));

const relativeFontSize = (num) => (num / 10 > 5 ? num / 10 : 5);

const LogoSmLoadingActive = (props) => <LogoSmLoading state="loading" {...props} />;
const LogoSmLoadingError = (props) => <LogoSmLoading state="error" {...props} />;

const Image = ({
  entity = {},
  alt,
  className,
  imageClassName,
  width = 100,
  height = '',
  strategy = 'outside',
  BackgroundLoadingComponent = LogoSmLoadingActive,
  NoImageComponent = LogoSmLoading,
  ErrorComponent = LogoSmLoadingError,
}) => {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isNotFound, setIsNotFound] = useState(!entity?.properties?.image?.url);

  const handleError = () => setIsError(true);

  const handleLoad = (e) => {
    const { target } = e;
    setIsLoaded(true);
    if (target?.naturalHeight === 30 && target?.naturalWidth === 30) {
      setIsError(true);
    }
  };

  const imageUrl = entity?.properties?.image?.url;

  useEffect(() => {
    if (isNotFound !== !imageUrl) {
      setIsNotFound(!imageUrl);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  const containerStyle = useMemo(() => ({
    fontSize: `${relativeFontSize(width || height)}px`,
    minWidth: width && `${width}px`,
    minHeight: height && `${height}px`,
  }), [width, height]);

  const props = useMemo(() => ({
    className: clsx(classes.img, imageClassName),
    src: `https://images.qloo.com/i/${entity.entity_id}-${width}x${height}-${strategy}.jpg`,
    alt: alt || entity?.name,
  }), [classes.img, imageClassName, entity.entity_id, entity?.name, width, height, strategy, alt]);

  return (
    <div className={clsx(classes.imageContainer, className)} style={containerStyle}>
      {
        !isError && !isNotFound && !!imageUrl && (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img
            {...props}
            className={clsx(props.className, !isLoaded && classes.imageLoading)}
            onLoad={handleLoad}
            onError={handleError}
          />
        )
      }
      {
        isError ? <ErrorComponent {...props} />
          : !isLoaded && isNotFound ? <NoImageComponent {...props} />
            : !isLoaded ? <BackgroundLoadingComponent {...props} />
              : null
      }
    </div>
  );
};

export default Image;
