import * as React from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.text.primary, 0.08),
  color: alpha(theme.palette.background.default, 1),
  transition: theme.transitions.create('color'),
  '&:focus-within': {
    color: alpha(theme.palette.text.primary, 1),
    backgroundColor: alpha(theme.palette.text.primary, 0.25),
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.text.primary, 0.30),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const HeaderSearchField = ({
  onBlur, value = '', onUpdateSearch, ...props
}) => {
  const handleChange = (event) => {
    const { value: tv } = event.target;

    onUpdateSearch && onUpdateSearch(tv);
  };

  return (
    <Search {...props}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        // placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        onBlur={onBlur}
        value={value}
        onChange={handleChange}
      />
    </Search>
  );
};

export default HeaderSearchField;
