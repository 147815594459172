import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { useNavigate, Link } from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import {
  useAuth,
} from '@frontegg/react';
import Typography from '@mui/material/Typography';
import AppBar from './AppBar';
import useDebounce from '../../lib/useDebounce';
import { ReactComponent as Logo } from '../Logo.svg';
import { ReactComponent as LogoSm } from '../LogoSm.svg';
import HeaderSearchField from './HeaderSearchField';
import {
  SEARCH_ENABLED,
} from '../../lib/pages';
import usePermission from '../../lib/usePermissionsBase';
import NavigationButtons from './NavigationButtons';
import UserBadge from './UserBadge';

const makeRequestsLink = ({ query }) => `/requests${!!query && (`?${new URLSearchParams({
  requests: `https://api.qloo.com/search?query=${query}`,
}).toString()}`)}`;

const HeaderSearchFieldConnected = (props) => {
  const [query, setQuery] = useState();
  const navigate = useNavigate();
  const deQuery = useDebounce(query, 500);
  // const previousQuery = usePrevious(deQuery);

  useEffect(() => {
    if (deQuery) {
      const link = makeRequestsLink({ query });
      navigate(link);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deQuery]);

  return (
    <HeaderSearchField
      {...props}
      onBlur={() => setQuery('')}
      value={query}
      onUpdateSearch={setQuery}
    />
  );
};

const StyledHeaderSearchFieldConnected = styled(HeaderSearchFieldConnected)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none !important',
  },
}));

export const useStyles = makeStyles((theme) => ({
  logoContainer: {
    minWidth: '80px',
  },
  logo: {
    opacity: 0.9,
    height: '41px',
    fill: 'rgba(0,0,0,0.8)',
    verticalAlign: 'middle',
    marginLeft: '1%',
  },
  logoLarge: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    marginRight: '30px !important',
  },
  LogoSmall: {
    height: '35px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
}));

const Header = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth();
  const [canShowSearchField] = usePermission('api.route./search');

  return (
    <AppBar>
      <Link to="/">
        <Typography
          variant="h6"
          noWrap
          className={classes.logoContainer}
        >
          <LogoSm className={clsx(classes.logo, classes.LogoSmall)} />
          <Logo className={clsx(classes.logo, classes.logoLarge)} />
        </Typography>
      </Link>

      { isAuthenticated && <NavigationButtons /> }

      <div className={classes.grow} />

      {
        !!SEARCH_ENABLED && canShowSearchField && <StyledHeaderSearchFieldConnected />
      }

      { isAuthenticated && <UserBadge /> }
    </AppBar>
  );
};

export default Header;
