import React from 'react';
import clsx from 'clsx';
import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  progressBar: {
    height: '3px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: 'rgba(201,206,234,0.8)',
  },
}));

const ProgressBar = ({ className, sx }) => {
  const classes = useStyles();
  return <LinearProgress className={clsx(classes.progressBar, className)} color="secondary" sx={sx} />;
};

export default ProgressBar;
