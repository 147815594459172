import { useMemo } from 'react';
import { useAuth } from '@frontegg/react';

const TENANT_PERMS = {
  DEFAULT: {
    // 'api.category.urn:entity:actor': true,
    // 'api.category.urn:entity:album': true,
    // 'api.category.urn:entity:artist': true,
    // 'api.category.urn:entity:author': true,
    // 'api.category.urn:entity:book': true,
    'api.category.urn:entity:brand': true,
    // 'api.category.urn:entity:destination': true,
    // 'api.category.urn:entity:director': true,
    // 'api.category.urn:entity:hotel': true,
    // 'api.category.urn:entity:movie': true,
    // 'api.category.urn:entity:person': true,
    // 'api.category.urn:entity:place': true,
    // 'api.category.urn:entity:podcast': true,
    // 'api.category.urn:entity:restaurant': true,
    // 'api.category.urn:entity:tv_show': true,
    // 'api.category.urn:entity:videogame': true,
    'api.route./': true,
    'api.route./geospatial': true,
    'api.route./geospatial/describe': true,
    'api.route./recommendations': true,
    'api.route./recommendations/describe': true,
    // 'feature.entity.qa-image-component': true,
    'feature.request-browser.addRequest': true,
    'feature.request-browser.comparoStats': true,
    // 'feature.request-browser.edit-url': true,
    'feature.request-browser.header': true,
    'feature.request-browser.heatmapControls': true,
    'feature.request-browser.mapControls': true,
    'feature.request-browser.mapInteractions': true,
    'feature.request-browser.requestSummary': true,
    // 'feature.request-browser.serverSelector': true,
    'feature.request-browser.viewTypeToggle': true,
    'page.requests': true,
    // 'page.details': true,
    // 'api.crossdomain': true,
    // 'feature.details.cross-domain-table': true,
    // 'feature.request-browser.edit-url': true,
    // 'feature.request-browser.openapi-validator': true,
  },
};

TENANT_PERMS.netflix = {
  ...TENANT_PERMS.DEFAULT,
  'api.category.urn:entity:movie': true,
  'api.category.urn:entity:tv_show': true,
  'api.route./geospatial/heatmap': true,
};

TENANT_PERMS.michelin = {
  ...TENANT_PERMS.DEFAULT,
  // 'api.route./entities': true,
  // 'api.route./search': true,
  'api.category.urn:entity:destination': true,
  'api.category.urn:entity:hotel': true,
  'api.category.urn:entity:place': true,
  'api.category.urn:entity:restaurant': true,
  'api.crossdomain': true,
};

TENANT_PERMS.friendOfQloo = {
  ...TENANT_PERMS.DEFAULT,
  'api.category.urn:entity:artist': true,
  'api.category.urn:entity:author': true,
  'api.category.urn:entity:book': true,
  'api.category.urn:entity:brand': true,
  'api.category.urn:entity:destination': true,
  'api.category.urn:entity:hotel': true,
  'api.category.urn:entity:movie': true,
  'api.category.urn:entity:person': true,
  'api.category.urn:entity:place': true,
  'api.category.urn:entity:podcast': true,
  'api.category.urn:entity:restaurant': true,
  'api.category.urn:entity:tv_show': true,
  'api.category.urn:entity:videogame': true,
  'api.route./geospatial/heatmap': true,
  'api.route./search': true,
  'api.route./recommendations': true,
  'api.route./trends': true,
  'api.crossdomain': true,
  'page.compare': true,
};

TENANT_PERMS.wpp = {
  ...TENANT_PERMS.DEFAULT,
  'api.category.urn:entity:artist': true,
  'api.category.urn:entity:author': true,
  'api.category.urn:entity:book': true,
  'api.category.urn:entity:brand': true,
  'api.category.urn:entity:destination': true,
  'api.category.urn:entity:hotel': true,
  'api.category.urn:entity:movie': true,
  'api.category.urn:entity:person': true,
  'api.category.urn:entity:place': true,
  'api.category.urn:entity:podcast': true,
  'api.category.urn:entity:restaurant': true,
  'api.category.urn:entity:tv_show': true,
  'api.category.urn:entity:videogame': true,
  'api.route./geospatial/heatmap': true,
  // 'api.route./search': true,
  'api.crossdomain': true,
};

TENANT_PERMS.seek = {
  ...TENANT_PERMS.DEFAULT,
  'api.category.urn:entity:brand': true,
  'api.route./analysis': true,
  'api.route./geospatial': true,
  'api.route./recommendations': true,
  'api.route./trends': true,
  'api.crossdomain': true,
};

// const getRoles = (user) => {
//   try {
//     return user.tenants.map(({ roles }) => roles.map(({ key }) => key.toLowerCase())).flat();
//   } catch (e) {
//     return [];
//   }
// };
// const hasRole = (user, role) => getRoles(user)?.indexOf(role) > -1;

const usePermission = (...perms) => {
  const { user } = useAuth();

  // console.log('user details', user);

  const permResponses = useMemo(() => {
    let toReturn = [];
    const tenantId = user?.tenantId;

    if (!tenantId) {
      toReturn = perms.map(() => false);
    }
    if (tenantId === 'qloo') {
      toReturn = perms.map(() => true);
    } else {
      const useperms = TENANT_PERMS[tenantId] || TENANT_PERMS.DEFAULT;
      toReturn = perms.map((p) => !!useperms[p]);
    }

    // if (hasRole(user, 'admin')) {
    //   console.log('usePermission admin');
    //   toReturn = perms.map(() => true);
    // } else if (hasRole(user, 'client')) {
    //   console.log('usePermission client', perms.length);
    //   toReturn = perms.map((p) => !!CLIENT_PERMS[p]);
    // } else {
    //   console.log('usePermission ??');
    //   toReturn = perms.map(() => false);
    // }

    console.log('usePermission returning', toReturn, 'from', perms, { user });
    return toReturn;
  }, [user]);

  return permResponses;
};

export default usePermission;

export const usePermissionObject = (...perms) => {
  const basePermissions = usePermission(...perms);
  const permissions = useMemo(() => (
    Object.fromEntries(perms.map((p, ii) => ([p, basePermissions[ii]])))
  ), [perms, basePermissions]);
  return permissions;
};
