import React, {
  lazy, Suspense, useEffect, useState,
} from 'react';
import {
  HashRouter as Router, Route, Routes, Navigate,
  useLocation,
  Link,
} from 'react-router-dom';
import {
  useLoginWithRedirect,
  useAuth,
  FronteggProvider,
  useAuthRoutes,
} from '@frontegg/react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';
// import { createBrowserHistory } from 'history';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ContextHolder } from '@frontegg/rest-api';
import Header from './components/Header/index';
import { usePagePerms } from './lib/usePermissions';

import ProgressBar from './components/ProgressBar';
import AccessDenied from './components/AccessDenied';

import '@qloo/design/src/colors.scss';

// const browserHistory = createBrowserHistory();

const TITLE = process.env.REACT_APP_SITE_TITLE || 'Qloo Insights';
const RouteAndTitle = ({ Component, title }) => (
  <>
    <Helmet
      titleTemplate={`%s - ${TITLE}`}
      defaultTitle={TITLE}
    >
      <title>{title}</title>
    </Helmet>
    <Component />
  </>
);

// const Home = lazy(() => import('./pages/Search'));
const Entity = lazy(() => import('./pages/Entity'));
// const Comparo = lazy(() => import('./pages/Comparo'));
const History = lazy(() => import('./pages/History'));
const RequestGrid = lazy(() => import('./pages/RequestGrid'));
const Logs = lazy(() => import('./pages/Logs'));
const HistoryQuery = lazy(() => import('./pages/HistoryQueryUrl'));
// const TastediveAdmin = lazy(() => import('./TastediveAdmin'));
const Config = lazy(() => import('./pages/Config'));
const Clients = lazy(() => import('./pages/Clients'));
const ClientLogs = lazy(() => import('./pages/ClientLogs'));
const ClientLog = lazy(() => import('./pages/ClientLog'));
const AudienceLabel = lazy(() => import('./pages/AudienceLabel'));
const Goals = lazy(() => import('./pages/Tests'));
const Shareables = lazy(() => import('./pages/Shareables'));
const Comparo = lazy(() => import('./pages/Comparo'));
const Insult = lazy(() => import('./pages/Insult'));
const Justify = lazy(() => import('./pages/Justify'));
const Test = lazy(() => import('./pages/TemporaryTest'));

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridGap: '0px',
    gridTemplateColumns: '120px auto',
    gridTemplateAreas: `"logo header"
                        "content content"`,
    backgroundColor: '#fff',

    [theme.breakpoints.down('m')]: {
      gridTemplateAreas: `"logo header"
                          "content content"`,
    },

    [theme.breakpoints.down('sm')]: {
      gridGap: '10px',
      gridTemplateColumns: 'auto',
      gridTemplateAreas: `
        "logo"
        "header"
        "content"
      `,
    },
  },
  content: {
    gridArea: 'content',
    backgroundColor: '#fff',
    margin: '64px 0 64px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '40px 1%',
    },
    position: 'relative',
    // overflowX: 'scroll',
    // '&::-webkit-scrollbar': {
    //   background: 'transparent',
    // },

    // paddingLeft: '5px',
    // paddingRight: '5px',
    // [theme.breakpoints.up('sm')]: {
    //   paddingLeft: '20px',
    //   paddingRight: '20px',
    // },
  },
  infobox: {
    marginTop: '50px',
  },
}));

// const useConvertUrlsToHashUrls = () => {
//   useEffect(() => {
//     const oldStyle = window.location.pathname + window.location.search;
//     if (oldStyle && oldStyle !== '/') {
//       console.log('Replacing old style /path with hash #/path');
//       const newurl = `/#${window.location.pathname}${window.location.search}`;
//       window.history.replaceState({ path: newurl }, '', newurl);
//     }
//   }, []);
// };

const LoginButton = () => <Link to="/account/login">Login</Link>;

const useAuthenticated = () => {
  const [firstLoad, setFirstLoad] = useState(true);
  const { isAuthenticated, isLoading, ...rest } = useAuth();
  // const isAuthenticated2 = useIsAuthenticated();

  // console.log('auth?', { isAuthenticated, isAuthenticated2, rest });
  const loginWithRedirect = useLoginWithRedirect();
  const { hostedLoginRedirectUrl } = useAuthRoutes();

  const loc = useLocation();

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isLoading && !isAuthenticated && loc.pathname !== hostedLoginRedirectUrl) {
      console.log('bonk', { isAuthenticated, isLoading, ...rest });
      window.localStorage.setItem('FRONTEGG_AFTER_AUTH_REDIRECT_URL', window.location.hash.substring(1));
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, hostedLoginRedirectUrl, loc.pathname]);

  return { isAuthenticated, isLoading: firstLoad ? true : isLoading };
};

const Logout = () => {
  const { baseUrl } = ContextHolder.getContext();
  useEffect(() => {
    window.localStorage.setItem('FRONTEGG_AFTER_AUTH_REDIRECT_URL', '/');
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  }, []);
  return null;
};

const RouterPage = () => {
  // const history = useNavigate();
  const classes = useStyles();

  const location = useLocation();
  console.log('location', location.pathname);

  const { isAuthenticated, isLoading } = useAuthenticated();

  const { firstUsablePage } = usePagePerms();

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <div className={classes.wrapper}>
          <Header />
          <div className={classes.content}>
            {
              (!isAuthenticated && !isLoading)
                ? <AccessDenied Button={LoginButton} />
                : (
                  <Suspense fallback={<ProgressBar />}>
                    <Routes>
                      <Route
                        exact
                        path="/"
                        element={
                          firstUsablePage ? <Navigate to={firstUsablePage.to} /> : null
                        }
                      />
                      {/* <Route exact path="/" element={<RouteAndTitle Component={RequestGrid} title="Requests" />} /> */}
                      <Route path="/entity/:id/:slug" element={<RouteAndTitle Component={Entity} title="Entity" />} />
                      <Route path="/entity/:id" element={<RouteAndTitle Component={Entity} title="Entity" />} />
                      <Route
                        path="/history/query"
                        element={
                          <RouteAndTitle Component={HistoryQuery} title="HistoryQuery" />
                        }
                      />
                      <Route path="/history" element={<RouteAndTitle Component={History} title="History" />} />
                      <Route path="/requests" element={<RouteAndTitle Component={RequestGrid} title="Requests" />} />
                      <Route path="/config" element={<RouteAndTitle Component={Config} title="Config" />} />
                      <Route
                        path="/clientLogs/:clientSlug"
                        element={
                          <RouteAndTitle Component={ClientLog} title="Client Logs" />
                        }
                      />
                      <Route path="/clientLogs" element={<RouteAndTitle Component={ClientLogs} title="Clients" />} />
                      <Route path="/clients/*" element={<RouteAndTitle Component={Clients} title="Clients" />} />
                      {/* <Route path="/db/admin" element={
                        <RouteAndTitle Component={TastediveAdmin} title="Database Admin" />} */}
                      <Route path="/logs" element={<RouteAndTitle Component={Logs} title="Logs" />} />
                      <Route
                        path="/audience_label"
                        element={
                          <RouteAndTitle Component={AudienceLabel} title="AudienceLabel" />
                        }
                      />
                      <Route path="/goals" element={<RouteAndTitle Component={Goals} title="Goals" />} />
                      <Route path="/shareables" element={<RouteAndTitle Component={Shareables} title="Shareables" />} />
                      <Route path="/compare" element={<RouteAndTitle Component={Comparo} title="Compare" />} />
                      <Route path="/insult" element={<RouteAndTitle Component={Insult} title="insult" />} />
                      <Route path="/justify" element={<RouteAndTitle Component={Justify} title="justify" />} />
                      <Route path="/logout" element={<RouteAndTitle Component={Test} title="Logout" />} />
                      {/* <Route path="/logout" element={<Logout />} /> */}
                    </Routes>
                  </Suspense>
                )
            }
          </div>
        </div>
      </SnackbarProvider>
    </QueryParamProvider>
  );
};

const FRONT_EGG_CONFIG = {
  baseUrl: process.env.REACT_APP_FRONT_EGG_URL,
  clientId: process.env.REACT_APP_FRONT_EGG_CLIENT_ID,
};

const FRONT_EGG_THEME = {
  loginBox: {
    themeName: 'classic',
  },
};

// const theme = createTheme({
//   palette: {
//     type: 'light',
//     primary: {
//       main: '#929292',
//     },
//   },
// });

const theme = createTheme({
  palette: {
    // primary: {
    //   main: '#000000',
    // },
    secondary: {
      main: '#2962ff',
    },
  },
});

// eslint-disable-next-line arrow-body-style
const RouterAuthWrapped = () => {
  // TODO: dark theme works but all colors need to be replaced with mui variables

  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // const theme = React.useMemo(
  //   () => createTheme({
  //     palette: {
  //       mode: prefersDarkMode ? 'dark' : 'light',
  //     },
  //   }),
  //   [prefersDarkMode],
  // );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <FronteggProvider
          contextOptions={FRONT_EGG_CONFIG}
          themeOptions={FRONT_EGG_THEME}
          urlStrategy="hash"
          hostedLoginBox
        >
          <RouterPage />
        </FronteggProvider>

      </Router>
    </ThemeProvider>
  );
};

export default RouterAuthWrapped;
